import styled from "styled-components";
import { Palette } from "../../theme/Palette";
import { Viewport } from "../../utilities/Constants/Viewport";

export const Root = styled.div`
    font-size: 16px;
    line-height: 1.4;
    color: ${Palette.Neutral90};
`;

export const DotRoot = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 32px 0px;
`;

export const DotContainer = styled.div`
    display: grid;
    grid-template-columns: 5px 5px 5px;
    grid-column-gap: 4px;
`;

export const Dot = styled.div`
    height: 5px;
    width: 5px;
    background-color: ${Palette.Neutral30};
    border-radius: 50%;
    display: inline-block;
`;

export const FormContainer = styled.div`
    display: grid;
    grid-template-columns: auto 128px;
    grid-column-gap: 16px;
    padding: 16px 0px;
    max-width: 560px;

    @media screen and (max-width: ${Viewport.Small}px) {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        grid-row-gap: 16px;
    }
`;

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 32px;
`;

export const MessageContainer = styled.div`
    margin-top: 32px;
`;

export const Bold = styled.span`
    font-weight: 700;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
