import React from "react";
import { SEO } from "../components/SEO/SEO";
import { useTranslation } from "react-i18next";
import { GatsbyImage, getImage, getSrc, IGatsbyImageData } from "gatsby-plugin-image";
import { NavigationHeader } from "../components/NavigationHeader/NavigationHeader";
import {
    Root,
    Breadcrumb,
    Subtitle,
    Section,
    Header,
    H1,
    ContentsHeading,
    ContentRoot,
    PageContainer,
    StickyContainer,
    SpacerContainer,
} from "../page-styles/markdown.styles";
import { RouterLink } from "../components/RouterLink/RouterLink";
import { Footer } from "../components/Footer/Footer";
import { TableOfContents } from "../components/TableOfContents/TableOfContents";
import { graphql } from "gatsby";
import { CoderOneRoute } from "../components/Pages/CoderOneRoute";
import { BlogFootnote } from "../components/BlogFootnote/BlogFootnote";

const Template = ({ data }) => {
    const [t] = useTranslation();
    const { markdownRemark } = data;
    const { frontmatter, html, tableOfContents, timeToRead } = markdownRemark;
    const { title, date, description, featuredImage, author, tag, canonicalUrl } = frontmatter;
    const image = getImage(featuredImage) as IGatsbyImageData;
    const featuredImageSrc = `https://${process.env.GATSBY_HOST}${getSrc(featuredImage)}`;
    return (
        <React.Fragment>
            <NavigationHeader />
            <SEO title={title} description={description} socialImage={featuredImageSrc} canonicalUrl={canonicalUrl} />
            <Root>
                <PageContainer>
                    <StickyContainer>
                        <ContentsHeading>{t("contents")}</ContentsHeading>
                        <TableOfContents dangerouslySetInnerHTML={{ __html: tableOfContents }} />
                    </StickyContainer>
                    <ContentRoot>
                        <Header>
                            <Breadcrumb>
                                <RouterLink href={CoderOneRoute.BlogIndex}>{t("allBlogs")}</RouterLink> / {title}
                            </Breadcrumb>
                            <H1>{title}</H1>
                            <Subtitle>
                                {date} • {author} • {tag} • {t("readingTime", { minutes: timeToRead })}
                            </Subtitle>
                        </Header>

                        <Section>
                            <GatsbyImage image={image} alt={title} style={{ marginTop: 16, marginBottom: 16 }} />
                        </Section>

                        <Section className="markdown-body" dangerouslySetInnerHTML={{ __html: html }} />
                        <BlogFootnote />
                    </ContentRoot>
                    <SpacerContainer></SpacerContainer>
                </PageContainer>
            </Root>
            <Footer />
        </React.Fragment>
    );
};
export default Template;

export const pageQuery = graphql`
    query ($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            tableOfContents(absolute: true, pathToSlugField: "frontmatter.slug")
            timeToRead
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
                description
                author
                tag
                canonicalUrl
                featuredImage {
                    childImageSharp {
                        gatsbyImageData(width: 800, height: 400, placeholder: BLURRED, transformOptions: { fit: COVER })
                    }
                }
            }
        }
    }
`;
