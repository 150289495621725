import * as React from "react";
import { useCallback, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Root, DotRoot, DotContainer, Dot, FormContainer, FormWrapper, MessageContainer } from "./BlogFootnote.styles";
import { TextInput } from "../TextInput/TextInput";
import { StateButton } from "../StateButton/StateButton";
import { telemetry } from "../../utilities/Telemetry/Telemetry";
import { TelemetryEvent } from "../../utilities/Telemetry/TelemetryEvent";

interface IProps {
    readonly showSubscribeForm?: boolean;
}

export const BlogFootnote: React.FC<React.PropsWithChildren<IProps>> = ({ showSubscribeForm = true }) => {
    const [t] = useTranslation();
    const [email, setEmail] = useState("");
    const [isFormDisplayed, setIsFormDisplayed] = useState(true);

    const onEmailChanged = useCallback(
        (_: unknown, value: any) => {
            setEmail(value);
        },
        [setEmail]
    );

    const onSubmit = useCallback(async () => {
        telemetry.Log(TelemetryEvent.SubscribeNewsletterInit, email);
        setIsFormDisplayed(false);
    }, [email]);

    return (
        <Root>
            <DotRoot>
                <DotContainer>
                    <Dot></Dot>
                    <Dot></Dot>
                    <Dot></Dot>
                </DotContainer>
            </DotRoot>
            {showSubscribeForm && (
                <FormWrapper>
                    <Trans key="blogPage.subscribe">Subscribe to get the latest posts in your inbox:</Trans>
                    {isFormDisplayed ? (
                        <FormContainer>
                            <TextInput placeholder={t("email")} value={email} onChange={onEmailChanged} required />
                            <StateButton type="submit" onClick={onSubmit}>
                                {t("subscribe")}
                            </StateButton>
                        </FormContainer>
                    ) : (
                        <MessageContainer>{t("subscribeSuccessMessage")}</MessageContainer>
                    )}
                </FormWrapper>
            )}
        </Root>
    );
};
